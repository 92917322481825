import styled from "styled-components";

export const FooterLegendContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: calc(100% - 0.85%);
  padding: 10px;
  background: ${({ theme }) => theme.white};
  margin-top: 10px;
}
`;
export const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
`;

export const Label = styled.div`
  padding-left: 10px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 400;
`;

export const LegendSVGIcon = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 2px;
`;

export const LegendSquare = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border-width: 1px;
  border-style: ${({ borderStyle }) => borderStyle};
  border-color: ${({ borderColor }) => borderColor};
  background: ${({ backgroundColor }) => backgroundColor};
`;

import styled from "styled-components";

export const StyledCustomSnack = styled.div`
  position: fixed;
  bottom: 30px;
  left: 50%;
  height: ${({ size }) => size}px;
  padding: 20px;
  transform: translateX(-50%);

  display: flex;
  gap: 20px;

  font-size: 14px;
  font-weight: 500;

  color: ${({ theme }) => theme.white};
  text-align: center;

  border-radius: 10px;

  z-index: 5000;

  a {
    color: ${({ theme }) => theme.primary.lighter};
    text-decoration: underline;
  }

  &.success {
    background-color: ${({ theme }) => theme.success.main};
  }

  &.warning {
    background-color: ${({ theme }) => theme.primary.main};
  }
`;

import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { errorModal } from "~/src/utils/errors";
import LoadMore from "~/src/components/load-more";
import ShiftItem from "~/src/containers/provide/components/shift-column";
import getShifts from "../graphql/get-shifts";
import { GET_SHIFT_BOOKED_MEMBERS } from "../graphql/get-shift-booked-members";
import { onAddShiftBookings, onUpdateRecipients } from "../reducer";
import { ErrorLabel, Scroll } from "../notifications.styles";

const SHIFTS_LIMIT = 5;

const Shifts = ({
  shifts = [],
  totalResults,
  areShiftsLoading,
  fetchMore,
  onAddShiftBookings,
  recipientMembers,
  onUpdateRecipients,
  bookingsWithShiftId,
  startTime,
  endTime
}) => {
  const [offset, setOffset] = useState(0);
  const [shiftHasNoBookings, setShiftHasNoBookings] = useState(false);
  const { data, loading: isGetShiftBookedMembersLoading } = useQuery(
    GET_SHIFT_BOOKED_MEMBERS,
    {
      variables: {
        bookingsOffset: 0,
        bookingsLimit: 999,
        shiftId: bookingsWithShiftId
      }
    }
  );

  const bookings = data?.account?.shift?.bookings?.data || [];

  useEffect(() => {
    if (!isGetShiftBookedMembersLoading && bookingsWithShiftId) {
      if (bookings.length > 0) {
        const membersToAdd = bookings.reduce((acc, { member }) => {
          if (
            member &&
            !recipientMembers.some(recipient => recipient.id === member.id)
          ) {
            acc.push(member);
          }
          return acc;
        }, []);

        membersToAdd.forEach(onUpdateRecipients);
        setShiftHasNoBookings(false);
      } else {
        setShiftHasNoBookings(true);
      }
    }
  }, [isGetShiftBookedMembersLoading, bookings]);

  useEffect(() => {
    setOffset(0);
  }, [startTime, endTime]);

  const handleLoadMore = async () => {
    const canLoadMoreShifts = offset < totalResults;
    if (!areShiftsLoading && canLoadMoreShifts) {
      try {
        const newOffset = offset + SHIFTS_LIMIT;
        await fetchMore(newOffset);
        setOffset(newOffset);
      } catch (e) {
        errorModal(e);
      }
    }
  };

  const noShiftsFound = !areShiftsLoading && shifts.length === 0;

  const hasMore = shifts.length !== totalResults;

  return (
    <div>
      {shiftHasNoBookings && (
        <ErrorLabel>This shift has no accepted bookings</ErrorLabel>
      )}

      {areShiftsLoading && <p>Loading...</p>}

      {noShiftsFound ? (
        <p>No shifts found</p>
      ) : (
        <Scroll>
          {shifts.length > 0 &&
            shifts.map((shift, i) => (
              <ShiftItem
                key={i}
                shouldHideApplicants
                shouldHideAutoAccept
                shift={shift}
                onPartnerClick={() => onAddShiftBookings(shift.id)}
              />
            ))}

          {hasMore && (
            <LoadMore
              isLoading={areShiftsLoading}
              onLoadMore={handleLoadMore}
            />
          )}
        </Scroll>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onAddShiftBookings,
      onUpdateRecipients
    },
    dispatch
  );

const mapStateToProps = s => ({
  shiftSearchText: s.notifications.shiftSearchText,
  selectedFilters: s.notifications.selectedFilters,
  selectedFilterOptions: s.notifications.selectedFilterOptions,
  bookingsWithShiftId: s.notifications.bookingsWithShiftId,
  recipientMembers: s.notifications.recipientMembers,
  startTime: s.notifications.selectedFilterOptions["shift-date"]?.startTime,
  endTime: s.notifications.selectedFilterOptions["shift-date"]?.endTime
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getShifts(SHIFTS_LIMIT)(Shifts));

import React, { useEffect, useState } from "react";

import Icon from "~/src/components/icon";

import { StyledCustomSnack } from "./snack.styles";

export default function({ snack, onClose }) {
  const [snackTimeout, setSnackTimeout] = useState(null);

  useEffect(() => {
    clearTimeout(snackTimeout);

    if (snack?.severity === "success") {
      setSnackTimeout(setTimeout(() => onClose(), 5000));
    }
  }, [snack?.severity || null, snack?.message || null]);

  if (snack) {
    const size = snack.size === 2 ? "75" : "55";

    return (
      <StyledCustomSnack className={snack.severity} size={size}>
        <Icon name={Icon.names.INFO} color={Icon.colors.WHITE} size={14} />

        {snack.message}

        <div style={{ cursor: "pointer" }}>
          <Icon
            name={Icon.names.CLOSE}
            color={Icon.colors.WHITE}
            size={14}
            onClick={() => onClose()}
          />
        </div>
      </StyledCustomSnack>
    );
  }

  return null;
}

import styled from "styled-components";

interface ContainerProps {
  isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: rows;
  justify-content: left;
  align-items: center;

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  height: 30px;
  padding: 5px;
  margin: 3px 1px;

  border: solid 2px
    ${({ theme, isDisabled }) =>
      isDisabled ? theme.neutral.main : theme.primary.main};

  border-radius: 5px;

  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.neutral.main : theme.white};

  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.white : theme.primary.main};

  &:hover {
    ${({ theme, isDisabled }) =>
      isDisabled ? "" : `background-color: ${theme.primary.light};`}
  }

  &:active {
    ${({ theme, isDisabled }) =>
      isDisabled ? "" : `background-color: ${theme.primary.main};`}
  }
`;

interface ButtonProps {
  isDisabled: boolean;
}

export const Button = styled.div<ButtonProps>`
  display: inline-block;

  height: 22px;
  width: 22px;
  padding: 2px;

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

interface TextProps {
  isDisabled: boolean;
}

export const Text = styled.div<TextProps>`
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.neutral.dark : theme.white};

  display: block;
  padding: 0px 5px 0px 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
`;

import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";
import { getColorToRGBA } from "@teamrota/rota-design";
import { COLORS } from "~/src/styles/config.style";
import { MAIN_COLUMN_WIDTH } from "../utils";

const fadeInAnimation = keyframes`${bounce}`;

export const Wrapper = styled.div`
  padding-right: 0.85%;
  display: flex;
  width: 100%;
  margin-left: ${({ isProviderScheduler }) =>
    isProviderScheduler ? MAIN_COLUMN_WIDTH : "0px"};
`;

export const StyledHead = styled.div`
  display: flex;
`;
export const StyledScrollBody = styled.div`
  padding-right: 0.85%;
  border-radius: 4px;
  height: 50vh;
  min-height: 490px;
  overflow: hidden scroll;
  flex-grow: 1;
`;

export const StyledButtonWrapper = styled.div`
  background: white;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledNoMembers = styled.div`
  background: white;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 200px;
  font-size: 16px;
`;

export const StyledScheduleRow = styled.div`
  display: flex;
`;

export const FadeInSpan = styled.span`
  animation: 2s ${fadeInAnimation} infinite;
  display: center;
  justify-content: center;
  align-items: center;
`;

export const StyledOpenShiftsContainer = styled.div`
  height: 125px;
`;

export const StyledBody = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
`;

export const StyledFocusShift = styled.div`
  position: relative;
  top: 0;

  flex-grow: 0;
  width: calc(${MAIN_COLUMN_WIDTH} - 12px);

  animation: expandFocusC 0.25s ease-out forwards;
  @keyframes expandFocusC {
    from {
      width: 0;
    }
    to {
      width: calc(${MAIN_COLUMN_WIDTH} - 12px);
    }
  }

  overflow: hidden;
`;

export const StyledFocusHead = styled.div`
  position: relative;
  height: 35px;
  width: calc(${MAIN_COLUMN_WIDTH} - 17px);

  border-radius: 4px 4px 0px 0px;

  padding: 10px;

  font-weight: 500;
  text-align: center;

  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primary.main};

  animation: expandFocusA 0.25s ease-out forwards;
  @keyframes expandFocusA {
    from {
      left: calc(-${MAIN_COLUMN_WIDTH} - 17px);
    }
    to {
      left: 0;
    }
  }
`;

export const StyledFocusBody = styled.div`
  position: relative;
  min-height: 155px;
  width: calc(${MAIN_COLUMN_WIDTH} - 17px);

  font-size: 16px;

  border-right: solid 5px ${({ theme }) => theme.primary.main};
  border-left: solid 5px ${({ theme }) => theme.primary.main};
  border-bottom: solid 5px ${({ theme }) => theme.primary.main};

  background-color: ${({ theme }) => theme.white};

  animation: expandFocusB 0.25s ease-out forwards;
  @keyframes expandFocusB {
    from {
      left: calc(-${MAIN_COLUMN_WIDTH} - 17px);
    }
    to {
      left: 0;
    }
  }
`;

export const StyledMemberGrid = styled.div`
  flex-grow: 1;
  min-height: 490px;
  background-color: ${({ theme }) => theme.white};
`;

const styles = {
  mainTh: {
    textAlign: "center",
    border: "none",
    width: "208px",
    minWidth: "208px"
  },
  tg: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    display: "block"
  },
  th: {
    border: `0 1px 1px 1px solid ${COLORS.GRID_GREY}`,
    width: "1px",
    height: 60,
    paddingBottom: "10px"
  },
  td: {
    border: `1px solid ${COLORS.GRID_GREY}`,
    paddingLeft: "3px",
    paddingRight: "3px",
    width: "144px",
    height: "60px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "default",
    maxWidth: "200px"
  },
  tr: {
    height: 60,
    cursor: "default"
  },
  createShift: {
    height: "100%",
    border: "1px solid #c4c4c4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "3px"
  },
  emptyRowContainer: {
    minWidth: "144px",
    textAlign: "center",
    margin: 40,
    fontSize: 18,
    fontWeight: 400,
    marginTop: 70
  },
  loadMoreWrapper: {
    border: `1px solid ${COLORS.GRID_GREY}`,
    paddingLeft: "3px",
    paddingRight: "3px",
    textAlign: "center",
    height: "80px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "default"
  }
};

export default styles;

export const StyledDaySurround = styled.div`
  display: flex;
  width: 100%;
  padding-right: 30px;
`;

export const StyledDayContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateText = styled.p`
  opacity: ${({ isDailyView, isActive }) =>
    isDailyView && !isActive ? 0.4 : 1};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.white : "none"};
  padding: 5px 15px;
  margin: 0;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.white : getColorToRGBA(theme.white, 0.5)};
  }

  text-align: center;
  font-size: "14px";
  font-weight: 500;
  color: ${({ isToday, theme }) =>
    isToday ? theme.primary.main : theme.text.primary};
`;

export const StyledGridHeader = styled.div`
  background-color: ${({ theme }) => theme.primary.main};
  display: flex;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.white};
  align-items: center;
  height: 35px;

  animation: expandHeight 0.25s ease-out forwards;

  @keyframes expandHeight {
    from {
      height: 0;
    }
    to {
      height: 35px;
    }
  }
`;

export const StyledDay = styled.div`
  min-width: calc(${MAIN_COLUMN_WIDTH} - 12px);
  padding: 10px;
  text-align: center;
`;

export const StyledFocusHeader = styled.div`
  min-width: calc(${MAIN_COLUMN_WIDTH} - 12px);
  padding: 10px;
`;

export const StyledIconContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledShiftFocus = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: ${({ isShown }) => (isShown ? "55px" : "0px")};
`;

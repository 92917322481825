import { useQuery, gql } from "@apollo/client";

const GET_SHIFT_INFO = gql`
  query getShiftInfo($shiftId: ID!) {
    account {
      shift(id: $shiftId) {
        tags {
          id
          name
        }
      }
    }
  }
`;

export const useShiftInfo = shift => {
  const { loading, error, data } = useQuery(GET_SHIFT_INFO, {
    variables: { shiftId: shift?.id },
    fetchPolicy: "no-cache",
    skip: !shift?.id || shift?.tags // if already loaded
  });

  return {
    loading,
    error,
    tags: shift?.tags || data?.account?.shift?.tags || []
  };
};

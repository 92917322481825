import styled from "styled-components";

import { iconNew } from "@teamrota/rota-design";

const { Sun, Moon } = iconNew;

export const StyledFocusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  background-color: ${({ theme }) => theme.white};

  padding: 2px 0;

  font-size: 14px;
  font-weight: 500;

  & .chip-wrapper {
    height: 40px;
  }
`;

export const StyledPrompt = styled.span`
  padding: 8px;
`;

export const StyledGroup = styled.span``;

export const StyledFilter = styled.span`
  color: ${({ theme }) => theme.neutral.medium};
`;

export const StyledChipMain = styled.div`
  font-size: 12px;
`;

export const StyledChipExtra = styled.div`
  font-size: 9px;
`;

export const StyledReset = styled.div`
  padding: 0;

  & button {
    min-width: 0;
    padding: 0px 5px;
  }
`;

export const StyledSun = styled(Sun)`
  position: relative;
  height: 10px;
  width: 10px;
  top: 2px;
  margin-right: 2px;
`;

export const StyledMoon = styled(Moon)`
  position: relative;
  height: 10px;
  width: 10px;
  top: 2px;
  margin-right: 2px;
`;

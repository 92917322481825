import React, { useContext, useEffect } from "react";
import moment from "moment-timezone";

import { RotaButtonNew, RotaChip } from "@teamrota/rota-design";

import { SHIFT_TYPES } from "~/src/consts";
import Icon from "~/src/components/icon";
import { FocusShiftContext } from "~/src/containers/provide-schedule/FocusShift";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import {
  StyledFocusContainer,
  StyledPrompt,
  StyledChipMain,
  StyledChipExtra,
  StyledReset,
  StyledSun,
  StyledMoon
} from "./focus-chips.styles";

function FocusChips({ user }) {
  const {
    focusShift,
    focusShiftTags,
    isVenueFilter,
    setIsVenueFilter,
    isRoleFilter,
    setIsRoleFilter,
    isAvailabilityFilter,
    setIsAvailabilityFilter,
    isTagsFilter,
    setIsTagsFilter,
    isNotUnsuitableFilter,
    setIsNotUnsuitableFilter,
    isNotExcludedFilter,
    setIsNotExcludedFilter
  } = useContext(FocusShiftContext);

  if (focusShift === null) {
    return "";
  }

  const isMemberAvailabilityEnabled =
    user?.account?.isMemberAvailabilityEnabled;

  useEffect(() => {
    if (!isMemberAvailabilityEnabled) {
      setIsAvailabilityFilter(false);
    }
  }, [focusShift, isMemberAvailabilityEnabled]);

  const isFiltered =
    isRoleFilter ||
    isVenueFilter ||
    isAvailabilityFilter ||
    isTagsFilter ||
    isNotUnsuitableFilter ||
    isNotExcludedFilter;

  const startTime = moment(focusShift?.startTime).format("HH:mm");
  const endTime = moment(focusShift.endTime).format("HH:mm");

  const tagNames =
    focusShiftTags?.length > 0
      ? focusShiftTags?.map(({ name }) => name).join(", ")
      : "None Required";

  return (
    <StyledFocusContainer>
      {isFiltered && <StyledPrompt>Show members who are:</StyledPrompt>}

      {isAvailabilityFilter && (
        <RotaChip
          chipText={
            <>
              <StyledChipMain>Available</StyledChipMain>
              <StyledChipExtra>
                {focusShift.type === SHIFT_TYPES.DAY && <StyledSun />}
                {focusShift.type === SHIFT_TYPES.NIGHT && <StyledMoon />}
                {startTime} - {endTime}
              </StyledChipExtra>
            </>
          }
          onClose={() => setIsAvailabilityFilter(false)}
          onClick={() => {}}
        />
      )}
      {isRoleFilter && (
        <RotaChip
          chipText={
            <>
              <StyledChipMain>Qualified with Role </StyledChipMain>
              <StyledChipExtra>
                {focusShift?.roleRate?.roleName}
              </StyledChipExtra>
            </>
          }
          onClose={() => setIsRoleFilter(false)}
          onClick={() => {}}
        />
      )}
      {isVenueFilter && (
        <RotaChip
          chipText={
            <>
              <StyledChipMain>In Service Area</StyledChipMain>
              <StyledChipExtra>for {focusShift?.venue?.name}</StyledChipExtra>
            </>
          }
          onClose={() => setIsVenueFilter(false)}
          onClick={() => {}}
        />
      )}
      {isTagsFilter && (
        <RotaChip
          chipText={
            <>
              <StyledChipMain>Tagged</StyledChipMain>
              <StyledChipExtra>{tagNames}</StyledChipExtra>
            </>
          }
          onClose={() => setIsTagsFilter(false)}
          onClick={() => {}}
        />
      )}
      {isNotUnsuitableFilter && (
        <RotaChip
          chipText={"Not Unsuitable"}
          onClose={() => setIsNotUnsuitableFilter(false)}
          onClick={() => {}}
        />
      )}
      {isNotExcludedFilter && (
        <RotaChip
          chipText={
            <>
              <StyledChipMain>Not Excluded</StyledChipMain>
              <StyledChipExtra>from {focusShift?.venue?.name}</StyledChipExtra>
            </>
          }
          onClose={() => setIsNotExcludedFilter(false)}
          onClick={() => {}}
        />
      )}

      {!isFiltered && <StyledPrompt>Showing all members</StyledPrompt>}

      <StyledReset>
        <RotaButtonNew
          variant="text"
          size="medium"
          onClick={() => {
            setIsRoleFilter(true);
            setIsVenueFilter(true);
            setIsAvailabilityFilter(true);
            setIsTagsFilter(true);
            setIsNotUnsuitableFilter(true);
            setIsNotExcludedFilter(true);
          }}
          iconLeft={
            <Icon name="CLEAR" size={25} color={Icon.colors.MAIN_ORANGE} />
          }
        >
          Reset
        </RotaButtonNew>
      </StyledReset>
    </StyledFocusContainer>
  );
}

export default getProfile(FocusChips);

import { capitalize, find, flow, get, max, padCharsStart } from "lodash/fp";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import { PARTNER_MANAGED_THRESHOLD } from "~/src/consts";
import { surgeRoleOptions } from "~/src/services/surge-pricing";
import Money from "~/src/utils/money";
import MoneyTransaction from "~/src/utils/money/money-transaction";

export const DATE_MONTH_YEAR_FORMAT = "Do MMM, YYYY";
export const DATE_TIME_FORMAT = "Do MMM [at] HH:mm";
export const DATE_TIME_SHORT_FORMAT = "DD/MM HH:mm";
export const DATE_TIME_FORMAT_WITH_YEAR = "Do MMM YYYY [at] HH:mm";
export const DATE_ONLY = "Do MMM";
export const TIME_FORMAT = "HH:mm";
export const DAY_DATE_TIME_FORMAT_WITH_YEAR = "dddd  Do MMM YYYY [at] HH:mm";
export const INVALID_DATE_TEXT = "Missing date";

export const pluraliseText = (count, label) => (count === 1 ? "" : `${label}s`);

export const formatTimeRemainingToManage = startTime => {
  const dateWithThreshold = moment(startTime).subtract(
    PARTNER_MANAGED_THRESHOLD,
    "days"
  );

  const daysRemaining = dateWithThreshold.diff(moment(), "days") || "";
  const hoursRemaining = dateWithThreshold.diff(moment(), "hours") % 24 || "";
  const minutesRemaining =
    dateWithThreshold.diff(moment(), "minutes") % 60 || "";

  const getLabel = (count, label) =>
    count === 0 ? "" : pluraliseText(count, label);

  const daysLabel = daysRemaining && getLabel(daysRemaining, "day");
  const hoursLabel = hoursRemaining && getLabel(hoursRemaining, "hour");
  const minutesLabel = minutesRemaining && getLabel(minutesRemaining, "minute");

  if (!daysRemaining && !hoursRemaining && !minutesRemaining)
    return "less than 1 minute";

  return `${daysRemaining} ${daysLabel} ${hoursRemaining} ${hoursLabel} ${minutesRemaining} ${minutesLabel}`;
};

const selectStartFormat = config => {
  if (config.includeYear) {
    return DATE_TIME_FORMAT_WITH_YEAR;
  } else if (config.includeDay) {
    return DAY_DATE_TIME_FORMAT_WITH_YEAR;
  }
  return DATE_TIME_FORMAT;
};

export function dateRangeToHuman(start, end, config = {}) {
  const startFormat = selectStartFormat(config);
  const endFormat = moment(start).isSame(end, "month")
    ? TIME_FORMAT
    : DATE_TIME_FORMAT;
  const startString = start
    ? moment(start).format(startFormat)
    : INVALID_DATE_TEXT;
  const endString = end ? moment(end).format(endFormat) : INVALID_DATE_TEXT;

  return `${startString} - ${endString}`;
}

// e.g Wednesday 11th Oct at 15:34
export function formatDateMonthTimeYear(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(DAY_DATE_TIME_FORMAT_WITH_YEAR);
}

// e.g 15:35
export function formatHoursMinutes(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(TIME_FORMAT);
}

// e.g. 28th May, 2014
export function formatDateMonthYear(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(DATE_MONTH_YEAR_FORMAT);
}

// e.g. 28th May at 14:30
export function formatDateMonthTime(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(DATE_TIME_FORMAT);
}

// e.g. 28/05 14:30
export function formatDateMonthTimeShort(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(DATE_TIME_SHORT_FORMAT);
}

// e.g. 28th May
export function formatDateMonth(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(DATE_ONLY);
}

// e.g. 28th May, 2017 at 09:00
export function formatDateFull(date) {
  if (!date) return INVALID_DATE_TEXT;
  return moment(date).format(DATE_TIME_FORMAT_WITH_YEAR);
}

// ['A', 'B', 'C'] -> A, B or C
export function joinListOr(array) {
  const arr = [...array];
  if (arr.length <= 1) return arr[0];
  return `${arr.slice(0, -1).join(", ")} or ${arr.slice(-1)}`;
}

/**
 * Pluralizes and formats the role for a shift
 */
export const roleLabel = (
  roleName,
  tags,
  numberRequested,
  acceptedBookings
) => {
  const labelParts = [];
  if (acceptedBookings !== undefined) labelParts.push(`${acceptedBookings} /`);
  if (numberRequested !== undefined) labelParts.push(numberRequested);
  labelParts.push(`${roleName}${numberRequested > 1 ? "s" : ""}`);
  // TODO add 'and' before last tag if there is more tha none tag
  if (tags && tags.length) {
    labelParts.push("with");
    labelParts.push(tags.join(", "));
  }
  return labelParts.join(" ").replace(")s", ")");
};

// Standard shift format, e.g. Thursday 10th Nov, 16:00 - 22:00
export const shiftTimes = (startTime, endTime) => {
  const startTimeLabel = moment(new ZonedDate(startTime)).format(
    "dddd Do MMM H:mma"
  );

  const endTimeLabel = moment(new ZonedDate(endTime)).format("H:mma");
  return `${startTimeLabel} - ${endTimeLabel}`;
};

export function camelCaseToNormalText(string) {
  if (typeof string !== "string") return string;

  return capitalize(string.replace(/([A-Z])/g, " $1"));
}

export const selectBlacklistTitle = rating => {
  switch (rating) {
    case 1:
      return "Gross Misconduct, are you sure? This member will not be coming back.";
    case 2:
      return "Bad shift. This member will not be coming back.";
    case 3:
      return "Not for us. This member will not be coming back.";
    default:
      return "Member will be marked as unsuitable";
  }
};

export function formatFullName(args) {
  if (!args) return "";
  const name = [];

  if (args.firstName) name.push(args.firstName);
  if (args.nickname) name.push(`(${args.nickname})`);
  if (args.lastName) name.push(args.lastName);

  return name.join(" ");
}

/* 
The method below is used to construct a string that will be used in different places of the platform
to show if there are bonuses, rewards or both in the current shift. 
*/
export const getShiftBonusType = (bonuses, bonusType, bonusAmount) => {
  if (bonusType && bonusType !== "" && bonusAmount)
    return `+ ${Money({ amount: bonusAmount }).toFormat()} ${bonusType}`;
  let bonusOrRewardString = "";
  let bonus = false;
  let reward = false;
  if (bonuses && bonuses.length > 0) {
    bonuses.forEach(bonusOrReward => {
      if (bonusOrReward.type === "bonus") {
        bonus = true;
      }
      if (bonusOrReward.type === "reward") {
        reward = true;
      }

      if (bonus && reward) {
        bonusOrRewardString = "+ Bonus & Reward";
      } else if (bonus) {
        bonusOrRewardString = "+ Bonus";
      } else if (reward) {
        bonusOrRewardString = "+ Reward";
      }
    });
  }

  return bonusOrRewardString;
};

export function getTotalCharge(args) {
  const { totalWithoutTax } = getShiftTotals(args);
  const VATString = args.shouldIncludeVAT ? "" : ` + ${args.taxRate}% VAT`;
  const bonus = Money({
    amount:
      !args.shouldNotAddBonusToTotal && args.bonusAmount ? args.bonusAmount : 0
  });

  const totalCost = new MoneyTransaction("getTotalCharge");
  totalCost.add(totalWithoutTax).add(bonus);

  if (args.shouldIncludeVAT) {
    totalCost.applyVAT();
  }

  return `${totalCost.toFormat()} ${
    args.bonus || args.bonusType
      ? getShiftBonusType(args.bonus, args.bonusType, args.bonusAmount)
      : ""
  } ${VATString}`;
}

const normaliseRates = ({ startTime, targetAccountId, roleOptions }) => {
  const options = surgeRoleOptions({
    startTime,
    targetAccountId,
    roleOptions
  });

  let roleRates = [];
  Object.keys(options).forEach(key => {
    roleRates = [...roleRates, ...options[key]];
  });

  return roleRates;
};

const getPolicyLength = ({ startTime, endTime, policy }) => {
  let length = moment(endTime).diff(startTime, "hours", true);

  if (
    policy &&
    (length < policy.minimumShiftLength ||
      length < length * (policy.minimumShiftPercentage / 100))
  ) {
    length = max([
      policy.minimumShiftLength,
      (policy.minimumShiftLength / 100) * length
    ]);
  }

  return length;
};

export function getShiftTotals({
  startTime,
  endTime,
  roleRates,
  roleRateId,
  numberRequested,
  targetAccountId,
  roleOptions,
  policy,
  isDayRate
}) {
  const length = getPolicyLength({ startTime, endTime, policy });

  const chargeRate = roleRates
    ? Money({
        amount: flow(find({ id: roleRateId }), get("chargeRate"))(roleRates)
      })
    : flow(
        find({ value: roleRateId ? roleRateId.toString() : "" }),
        get("chargeRate")
      )(normaliseRates({ startTime, targetAccountId, roleOptions }));

  const chargePerHour = (chargeRate || Money()).multiply(numberRequested);

  const totalCost = new MoneyTransaction(`getShiftTotals ${chargeRate}`);

  if (isDayRate) {
    totalCost.add(chargePerHour);
  } else {
    totalCost.add(chargePerHour).multiply(length);
  }

  return {
    length,
    chargePerHour,
    totalWithoutTax: totalCost.current, // Money instance
    totalWithTax: totalCost.applyVAT().current // Money instance
  };
}

// Produce clock hours: 8:30
export function getLengthInClockHours(hours) {
  const hourDuration = Math.floor(hours).toString();

  const minuteDuration = ((hours * 60) % 60).toFixed(0);
  return `${padCharsStart("0")(2)(hourDuration)}:${padCharsStart("0")(2)(
    minuteDuration
  )}`;
}

export function getLengthInClockHoursFromMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  return `${padCharsStart("0")(2)(hours.toString())}:${padCharsStart("0")(2)(
    remainingMinutes.toString()
  )}`;
}

export function getHoursWorked(startTime, endTime) {
  const hours = moment(endTime).diff(startTime, "hours", true);
  return getLengthInClockHours(hours);
}
export function getPolicyQueryParams(policy) {
  if (!policy) return "/policy";
  return [
    "/policy",
    `?minimumShiftLength=${policy.minimumShiftLength}`,
    `&minimumShiftPercentage=${policy.minimumShiftPercentage}`,
    `&cancellationPeriod=${policy.cancellationPeriod}`
  ].join("");
}

// Object to contain sort logic for various types
export const SORTERS = {
  byDateDesc: (x, y) => new ZonedDate(x.endTime) - new ZonedDate(y.endTime),
  byDateAsc: (x, y) => new ZonedDate(y.endTime) - new ZonedDate(x.endTime)
};

export const getTotalBonus = ({ bonuses, length, id }) => {
  const totalBonus = new MoneyTransaction(`getTotalBonusPerBooking [${id}]`);

  bonuses.forEach(bonus => {
    let bonusMoney = Money({ amount: bonus.amount || 0 });

    if (bonus.type === "hourly") {
      bonusMoney = bonusMoney.multiply(length);
    }

    totalBonus.add(bonusMoney);
  });

  return totalBonus;
};

export const getTotalBonusPerBooking = booking => {
  const length = get("bookingState.lengthFinal", booking);
  const total = getTotalBonus({
    bonuses: booking.bonuses,
    length,
    id: booking.id
  });

  return total.current; // Money instance
};

export const getTotalBonuses = shift => {
  const bookings = shift.bookings ? shift.bookings.data : shift;
  const totalBonus = new MoneyTransaction(`getTotalBonuses [${shift.id}]`);

  if (bookings.length) {
    bookings.forEach(booking => {
      totalBonus.add(getTotalBonusPerBooking(booking));
    });
  }

  return totalBonus.current;
};

export const getGrandTotalBonuses = shifts => {
  const totalBonus = new MoneyTransaction(
    `getGrandTotalBonuses [${shifts.map(({ id }) => id)}]`
  );

  shifts.forEach(shift => {
    totalBonus.add(getTotalBonuses(shift));
  });

  return {
    grandTotalBonusesExclVat: totalBonus.current, // Money instance
    grandTotalBonusesInclVat: totalBonus.applyVAT().current // Money instance
  };
};

export const GrandTotalStateBonusesWithVat = (totalStateBonuses, shifts) => {
  const totalAmount = new MoneyTransaction(`calculateGrandTotalStateBonuses`);

  totalStateBonuses.forEach((bonus, index) => {
    const bonusMoney = Money({ amount: bonus.totalBonus }).multiply(
      get(`[${index}].numberRequested`, shifts) || 1
    );
    totalAmount.add(bonusMoney);
  });

  return totalAmount.applyVAT().current;
};

export const getTotalBonusAmount = ({
  bonuses,
  policy,
  times,
  numberRequested
}) => {
  const totalBonusAmount = new MoneyTransaction(`getTotalBonusAmount`);

  for (const { startTime, endTime } of times) {
    const length = getPolicyLength({ policy, startTime, endTime });
    const bonustAmount = getTotalBonus({ bonuses, length }).current;

    totalBonusAmount.add(bonustAmount);
  }

  totalBonusAmount.multiply(numberRequested || 1);

  return {
    total: totalBonusAmount.current, // Money instance
    totalWithVat: totalBonusAmount.applyVAT().current // Money instance
  };
};

/**
 * @description returns human readable time from start of shift to end of shift
 * @param {String} start start datetime
 * @param {String} end end datetime
 * @returns {String}
 */
export function showShiftTimeRange(start, end) {
  const startDate = moment(start).format("Do MMM YYYY");
  const endDate = moment(end).format("Do MMM YYYY");
  const startTime = moment(start).format("HH:mm");
  const isSameDay = moment(startDate).isSame(endDate, "day");
  const endTime = moment(end).format("HH:mm");
  let dateString;
  if (isSameDay) {
    dateString = `${startDate} at ${startTime} - ${endTime}`;
  } else {
    dateString = `${startDate} ${startTime} to ${endDate} ${endTime}`;
  }
  return dateString;
}

// Get the last six most recent ratings
export function getLastSixRatings(arr) {
  if (!arr) {
    return [];
  }
  if (arr.length > 6) {
    return arr.slice(-6);
  }
  return arr;
}
/**
 * Formats money in human readable form with currency symbol, commas and right decimal numbers
 * @param {string} currency currency code for the country eg GPB for UK
 * @returns money format
 */
export const getFormatterMoney = (currency = "GBP") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501),
  });
};

export const getCurrencySubUnit = code => {
  switch (code) {
    case "EUR":
      return "cents";

    default:
      return "pence";
  }
};

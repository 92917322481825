import React, { useState, useEffect, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useLazyQuery } from "@apollo/client";

import { Role } from "@teamrota/authlib";

import { SORT_BY } from "~/src/consts";
import useAuth from "~/src/auth/hooks/use-auth";
import Loading from "~/src/components/loading";
import ProvideScheduleCalendarHeader from "~/src/containers/provide-schedule/CalendarHeader/calendar-header";
import ShiftCreateModal from "~/src/containers/scheduler/ShiftCreateModal";
import SchedulerGrid from "~/src/containers/scheduler/SchedulerGrid/scheduler-grid";
import { GET_REQUESTER_PROFILE } from "~/src/containers/provide-schedule/EditShiftModal/graphql/GetRequesterProfile";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { useSchedules } from "~/src/graphql/queries/use-schedules";

import {
  postShift,
  cancelPostShift,
  clearBonusState,
  countDraftShifts
} from "~/src/containers/scheduler/reducer";

import {
  CalendarContainer,
  GridWrapper,
  Wrapper,
  LoadingWrapper
} from "~/src/containers/scheduler/scheduler.styles";

import { useMemberBusy } from "~/src/graphql/queries/use-member-busy";
import { usePartners } from "~/src/graphql/queries/use-partners.js";

import ShiftEditModal from "./EditShiftModal";

import {
  getProvideScheduleStartDate,
  getProvideScheduleSortOption,
  setProvideScheduleSortOption,
  setProvideScheduleStartDate
} from "./storage";

import { provideFocusShiftContext, FocusShiftContext } from "./FocusShift";
import FulfilmentSnack from "./FulfilmentSnack";

const ProviderScheduler = ({
  policy,
  initialPostStart,
  postShift,
  countDraftShifts,
  countOfDraftShifts,
  draftShiftIds
}) => {
  const auth = useAuth();

  const currentUserId = auth.info.id;

  const isSelfProvider = auth.hasRoles(Role.PROVIDER, Role.REQUESTER);

  const { partners } = usePartners();

  const [isShiftCreateModalOpen, setIsShiftCreateModalOpen] = useState(false);
  const [roleIds, setRoleIds] = useState([]);
  const [venueIds, setVenueIds] = useState([]);
  const [homeVenueIds, setHomeVenueIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [editShiftModalShiftId, setEditShiftModalShiftId] = useState(null);
  const [editShiftModalIsEdit, setEditShiftModalIsEdit] = useState(false);

  const [filteredPartnerIds, setFilteredPartnerIds] = useState([]);
  const [startDate, setStartDate] = useState(
    getProvideScheduleStartDate() || moment().startOf("isoWeek")
  );
  const [isShowCancelledShifts, setIsShowCancelledShifts] = useState(false);
  const [isShowCancelledBookings, setIsShowCancelledBookings] = useState(false);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [shiftSources, setShiftSources] = useState([]);
  const [createdAfter, setCreatedAfter] = useState([]);
  const [availableOnDays, setAvailabilityWeekDays] = useState([]);

  const [selectedDayIndex, setSelectedDayIndex] = useState(null);

  const [sortBy, setSortBy] = useState(() => {
    const persistedSortOption = getProvideScheduleSortOption({
      id: currentUserId
    });

    return persistedSortOption || SORT_BY.FIRSTNAME;
  });

  const [snack, setSnack] = useState(null);

  const startTime = startDate.format();
  const endTime = startDate
    .clone()
    .add(6, "days")
    .startOf("day")
    .format();

  const { focusShift, focusFilters, clearFocusShift } = useContext(
    FocusShiftContext
  );

  useEffect(() => {
    clearFocusShift();
  }, [startDate]);

  let createdAfterDate = null;
  switch (createdAfter?.[0]) {
    case "HOUR":
      createdAfterDate = moment().startOf("hour");
      break;

    case "DAY":
      createdAfterDate = moment().startOf("day");
      break;

    case "YESTERDAY":
      createdAfterDate = moment()
        .startOf("day")
        .subtract(1, "day");

      break;

    case "WEEK":
      createdAfterDate = moment().startOf("isoWeek");
      break;
  }

  // run main schedule query

  const scheduleProps = {
    startTime,
    endTime,
    homeVenueIds,
    venueIds,
    roleIds,
    shiftTypes,
    shiftSources,
    createdAfter: createdAfterDate,
    availableOnDays,
    partnerIds: filteredPartnerIds,
    userIds,
    searchTerm,
    isShowCancelledShifts,
    isShowCancelledBookings,
    isSelfProvider,
    sortBy
  };

  const {
    schedulesLoading,
    scheduleData,
    refetchSchedules,
    forceRefetchSchedules
  } = useSchedules({
    ...scheduleProps,
    skip: !filteredPartnerIds.length
  });

  const schedule = scheduleData?.account?.schedule;

  useEffect(() => {
    const unassignedShifts = scheduleData?.account?.schedule?.unassignedShifts;
    if (focusShift && !schedulesLoading && unassignedShifts !== undefined) {
      // make sure the focussed shift is still returned in the resultset
      const ids = unassignedShifts
        .map(({ shifts }) => shifts.map(({ id }) => id))
        .flat();

      if (!ids.includes(focusShift?.id)) clearFocusShift();
    }
  }, [focusShift, schedulesLoading]);

  const isMemberAvailabilityEnabled =
    scheduleData?.account?.isMemberAvailabilityEnabled;

  // if a focussed shift is set, run the query again to retrieve focussed info for member grid
  const focusScheduleProps =
    focusShift !== null
      ? {
          ...scheduleProps,
          ...focusFilters,
          availableForShift: isMemberAvailabilityEnabled
            ? focusFilters.availableForShift
            : null
        }
      : null;

  const {
    scheduleData: focusScheduleData,
    loadMoreSchedules: loadMoreFocusSchedules,
    refetchSchedules: refreshFocusSchedules
  } = useSchedules({
    ...(focusShift !== null ? focusScheduleProps : scheduleProps),
    skip: !filteredPartnerIds.length
  });

  const focusSchedule = focusScheduleData?.account?.schedule;

  const refetchAllSchedules = async () => {
    await Promise.all([refetchSchedules(), refreshFocusSchedules()]);
  };

  // fetch member busy info
  const { busyLoading, busyData } = useMemberBusy({
    startTime,
    endTime,
    memberIds: (focusShift !== null
      ? focusSchedule?.scheduledMembers
      : schedule?.scheduledMembers
    )?.map?.(({ memberId }) => memberId)
  });

  // reconstitute fetched data so it can be accessed by memberId & date
  let busyByMember = undefined;
  if (!busyLoading && busyData !== undefined) {
    busyByMember = {};
    for (const busyMember of busyData) {
      busyByMember[busyMember.memberId] = {};
      for (const { date, blocks } of busyMember.entries) {
        busyByMember[busyMember.memberId][date] = blocks;
      }
    }
  }

  const [
    getRequesterProfile,
    { loading: requesterProfileLoading, data: requesterProfileData }
  ] = useLazyQuery(GET_REQUESTER_PROFILE);

  useEffect(() => {
    setProvideScheduleStartDate({ startDate });
  }, [startDate]);

  useEffect(() => {
    setProvideScheduleSortOption({
      id: currentUserId,
      sortOption: sortBy
    });
  }, [sortBy]);

  const user = requesterProfileData?.requesterProfile;
  const targetAccountId = user?.account?.id;

  const handlePartnerSelect = selectedPartnerIds => {
    selectedPartnerIds.length === 0
      ? setFilteredPartnerIds(partners.map(({ id }) => id))
      : setFilteredPartnerIds(selectedPartnerIds);
  };

  const handleShiftCellPress = async (shift, isEdit = false) => {
    if (shift?.id && shift?.sourceAccountId) {
      if (!auth.hasRole(Role.SHIFTS_EDIT)) return;

      await getRequesterProfile({
        variables: {
          userId: shift.sourceAccountId
        }
      });

      setEditShiftModalShiftId(shift.id);
      setEditShiftModalIsEdit(isEdit);
    }
  };

  const handleOpenShiftCreateModal = ({ startTime, scheduledMember }) => {
    if (!auth.hasRole(Role.SHIFTS_CREATE)) return;
    //this func is also happening in the dashboard for the side menu navigation
    const now = moment();
    const hour = now.hour();
    const minute = now.minute();
    const updatedStartTime = moment(startTime).set({
      hour: parseInt(hour, 10),
      minute: parseInt(minute + 15, 10)
    });

    postShift({
      defaultBreakMinutes: user?.account?.defaultBreakMinutes,
      ...(!!startTime && {
        startTime: updatedStartTime,
        endTime: moment(updatedStartTime)
          .clone()
          .endOf("day")
      }),
      ...(!!scheduledMember && {
        assignedMemberIds: [scheduledMember?.member?.id],
        assignedMemberName: `${scheduledMember?.member?.firstName} ${scheduledMember?.member?.lastName}`,
        assignedMemberRoleIds: scheduledMember?.member?.roles,
        memberType: scheduledMember?.member?.memberType,
        isGridSelectedShift: true
      })
    });

    setIsShiftCreateModalOpen(true);
  };

  return (
    <CalendarContainer>
      {partners && (
        <ProvideScheduleCalendarHeader
          setStartDate={setStartDate}
          startDate={startDate}
          setIsShiftCreateModalOpen={handleOpenShiftCreateModal}
          handleCountOfDraftShifts={() => countDraftShifts()}
          countOfDraftShifts={countOfDraftShifts}
          draftShiftIds={draftShiftIds}
          refetchSchedules={refetchSchedules}
          forceRefetchSchedules={forceRefetchSchedules}
          schedulesLoading={schedulesLoading}
          handlePartnerSelect={handlePartnerSelect}
          partners={partners}
          setRoleIds={setRoleIds}
          setVenueIds={setVenueIds}
          setHomeVenueIds={setHomeVenueIds}
          isShowCancelledShifts={isShowCancelledShifts}
          setIsShowCancelledShifts={setIsShowCancelledShifts}
          isShowCancelledBookings={isShowCancelledBookings}
          setIsShowCancelledBookings={setIsShowCancelledBookings}
          setShiftTypes={setShiftTypes}
          setShiftSources={setShiftSources}
          setCreatedAfter={setCreatedAfter}
          setAvailabilityWeekDays={setAvailabilityWeekDays}
          setSelectedUserIds={setUserIds}
          schedule={schedule}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      )}

      <Wrapper>
        <GridWrapper>
          {!schedulesLoading && schedule ? (
            <SchedulerGrid
              schedule={schedule}
              focusSchedule={focusSchedule}
              busyByMember={busyByMember}
              selectedDayIndex={selectedDayIndex}
              setSelectedDayIndex={setSelectedDayIndex}
              refetchSchedules={refetchAllSchedules}
              startDate={startDate}
              setHomeVenueIds={setHomeVenueIds}
              setRoleIds={setRoleIds}
              venueIds={venueIds}
              setVenueIds={setVenueIds}
              setSearchTerm={setSearchTerm}
              setSortBy={setSortBy}
              onPressGridCell={booking => handleOpenShiftCreateModal(booking)}
              onPressShiftCell={handleShiftCellPress}
              totalMembersCount={focusSchedule?.totalMembersCount}
              loadMore={loadMoreFocusSchedules}
              {...scheduleProps}
              isProviderScheduler
              isMemberAvailabilityEnabled={isMemberAvailabilityEnabled}
              setSnack={setSnack}
            />
          ) : (
            <LoadingWrapper>
              <Loading color="black" />
            </LoadingWrapper>
          )}
        </GridWrapper>
      </Wrapper>

      <ShiftCreateModal
        isOpen={isShiftCreateModalOpen}
        initialPostStart={initialPostStart}
        policy={policy}
        user={user}
        refetchSchedules={refetchAllSchedules}
        setIsShiftCreateModalOpen={setIsShiftCreateModalOpen}
        getRequesterProfile={getRequesterProfile}
        requesterProfileLoading={requesterProfileLoading}
        isProviderScheduler
        filteredPartners={partners?.filter(p =>
          filteredPartnerIds.includes(p.id)
        )}
      />

      {editShiftModalShiftId && filteredPartnerIds?.length && (
        <ShiftEditModal
          policy={policy}
          shiftId={editShiftModalShiftId}
          isEditMode={editShiftModalIsEdit}
          setIsEditMode={setEditShiftModalIsEdit}
          onClose={() => {
            setEditShiftModalShiftId(null);
            refetchAllSchedules();
          }}
          targetAccountId={targetAccountId}
          user={user}
          isProviderScheduler
          partners={partners}
          {...scheduleProps}
        />
      )}

      {snack && (
        <FulfilmentSnack snack={snack} onClose={() => setSnack(null)} />
      )}
    </CalendarContainer>
  );
};

const mapStateToProps = s => ({
  initialPostStart: s.scheduler?.initialPostStart,
  countOfDraftShifts: s.scheduler?.countOfDraftShifts,
  draftShiftIds: s.scheduler?.draftShiftIds
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postShift,
      clearBonusState,
      cancelPostShift,
      countDraftShifts
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getProfile(provideFocusShiftContext(ProviderScheduler)));

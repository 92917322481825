import React from "react";
import ReactTags from "react-tag-autocomplete";

import moment from "moment-timezone";
import find from "lodash/fp/find";
import getOr from "lodash/fp/getOr";

import { RotaButton } from "@teamrota/rota-design";

import { BOOKING_STATES_LABELS, SHIFT_SOURCES } from "~/src/consts";
import { removeAtIndex } from "~/src/utils";

import ActionDropdown from "~/src/components/action-dropdown";
import FiltersDateRange from "~/src/components/form-components/filters-date-range";

import { ButtonContainer } from "./filters.styles";
import getTagsByAccount from "./get-tags-by-account";

export const OPTIONS_BOOLSTATE = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const OPTIONS_TRISTATE = [
  { label: "Both", value: null },
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const SHIFT_SOURCE_LABELS = {
  [SHIFT_SOURCES.REQUESTED]: "Requested",
  [SHIFT_SOURCES.PROVIDED]: "Provided",
  [SHIFT_SOURCES.SHIFTSTREAM]: "ShiftStream"
};

export const SHIFT_SOURCE_OPTIONS = [
  { label: "Any", value: [] },
  {
    label:
      SHIFT_SOURCE_LABELS[SHIFT_SOURCES.REQUESTED] +
      " or " +
      SHIFT_SOURCE_LABELS[SHIFT_SOURCES.PROVIDED],
    value: [SHIFT_SOURCES.REQUESTED, SHIFT_SOURCES.PROVIDED]
  },
  {
    label: SHIFT_SOURCE_LABELS[SHIFT_SOURCES.REQUESTED],
    value: [SHIFT_SOURCES.REQUESTED]
  },
  {
    label: SHIFT_SOURCE_LABELS[SHIFT_SOURCES.PROVIDED],
    value: [SHIFT_SOURCES.PROVIDED]
  },
  {
    label: SHIFT_SOURCE_LABELS[SHIFT_SOURCES.SHIFTSTREAM],
    value: [SHIFT_SOURCES.SHIFTSTREAM]
  }
];

export const SHIFT_TYPE_OPTIONS = [
  { label: "Day", value: "DAY" },
  { label: "Night", value: "NIGHT" },
  { label: "Both", value: null }
];

export const BOOKING_STATE_OPTIONS = Object.entries(BOOKING_STATES_LABELS).map(
  ([id, name]) => ({
    id,
    name
  })
);

const findOption = (value, options) =>
  find(option => option.value === value, options || OPTIONS_TRISTATE);

const readDate = date => (date ? moment(date, "DD/MM/YYYY") : "");

const formatDate = date => (date ? moment(date).format("DD/MM/YYYY") : "");

const Filters = ({
  filters,
  roleOptions,
  serviceAreaOptions,
  onUpdate,
  isRotaAccount,
  onResetState,
  tagsByAccount = []
}) => (
  <div>
    <FiltersDateRange
      startTime={readDate(filters?.startTime)}
      endTime={readDate(filters?.endTime)}
      onChangeStartTime={startTime =>
        onUpdate({ startTime: formatDate(startTime) })
      }
      onChangeEndTime={endTime => onUpdate({ endTime: formatDate(endTime) })}
    />

    <p>Shift Source</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.shiftSources, SHIFT_SOURCE_OPTIONS)}
      onChange={option => onUpdate({ shiftSources: option.value })}
      options={SHIFT_SOURCE_OPTIONS}
    />

    <p>Cancelled bookings</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.hasCancelledBookings)}
      onChange={option => onUpdate({ hasCancelledBookings: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Applied shift</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.hasAppliedBookings)}
      onChange={option => onUpdate({ hasAppliedBookings: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Shift Type</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.shiftType, SHIFT_TYPE_OPTIONS)}
      onChange={option => onUpdate({ shiftType: option.value })}
      options={SHIFT_TYPE_OPTIONS}
    />

    <p>Booking states</p>
    <ReactTags
      autofocus={false}
      placeholder="Any"
      handleDelete={index => {
        const bookingStateIn = getOr([], "bookingStateIn", filters);
        onUpdate({
          bookingStateIn: removeAtIndex(bookingStateIn, index)
        });
      }}
      handleAddition={bookingState => {
        onUpdate({
          bookingStateIn: [
            ...getOr([], "bookingStateIn", filters),
            bookingState
          ]
        });
      }}
      tags={getOr([], "bookingStateIn", filters)}
      suggestions={BOOKING_STATE_OPTIONS}
    />

    <p>Finalised</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isFinalised)}
      onChange={option => onUpdate({ isFinalised: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Cancelled shifts</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isCancelled)}
      onChange={option => onUpdate({ isCancelled: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Under review</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isUnderReview)}
      onChange={option => onUpdate({ isUnderReview: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Restricted</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isRestricted)}
      onChange={option => onUpdate({ isRestricted: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Rebook</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isShiftRebook)}
      onChange={option => onUpdate({ isShiftRebook: option.value })}
      options={OPTIONS_BOOLSTATE}
    />

    <p>Featured</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isFeatured)}
      onChange={option => onUpdate({ isFeatured: option.value })}
      options={OPTIONS_TRISTATE}
    />

    <p>Roles</p>
    <ReactTags
      autofocus={false}
      placeholder="Any"
      handleDelete={index => {
        const roleIds = getOr([], "roleIds", filters);
        onUpdate({
          roleIds: removeAtIndex(roleIds, index)
        });
      }}
      handleAddition={role => {
        onUpdate({
          roleIds: [...getOr([], "roleIds", filters), role]
        });
      }}
      tags={filters.roleIds}
      suggestions={roleOptions}
    />

    <p>Tags</p>
    <ReactTags
      autofocus={false}
      placeholder="Any"
      handleDelete={index => {
        const tags = getOr([], "tags", filters);
        onUpdate({
          tags: removeAtIndex(tags, index)
        });
      }}
      handleAddition={role => {
        onUpdate({
          tags: [...getOr([], "tags", filters), role]
        });
      }}
      tags={filters.tags}
      suggestions={tagsByAccount}
    />

    <p>Service Areas</p>
    <ReactTags
      autofocus={false}
      placeholder="Any"
      handleDelete={index => {
        const serviceAreaIds = getOr([], "serviceAreaIds", filters);
        onUpdate({
          serviceAreaIds: removeAtIndex(serviceAreaIds, index)
        });
      }}
      handleAddition={serviceArea => {
        onUpdate({
          serviceAreaIds: [...getOr([], "serviceAreaIds", filters), serviceArea]
        });
      }}
      tags={filters.serviceAreaIds}
      suggestions={serviceAreaOptions}
    />

    <p>Partner managed</p>
    <ActionDropdown
      isFullWidth
      isBlock
      isOutline
      isSquare
      value={findOption(filters.isPartnerManaged)}
      onChange={isPartnerManaged =>
        onUpdate({ isPartnerManaged: isPartnerManaged.value })
      }
      options={OPTIONS_TRISTATE}
    />

    {isRotaAccount && (
      <div>
        <p>Registration/Waiting List</p>
        <ActionDropdown
          isFullWidth
          isBlock
          isOutline
          isSquare
          value={findOption(filters.isRegistration)}
          onChange={option => onUpdate({ isRegistration: option.value })}
          options={OPTIONS_TRISTATE}
        />
      </div>
    )}

    <ButtonContainer>
      <RotaButton variant="outlined" onClick={onResetState}>
        Reset filters
      </RotaButton>
    </ButtonContainer>
  </div>
);

export default getTagsByAccount(Filters);

import { gql } from "@apollo/client";
import VenueFragment from "../venue/venue.fragment";
import RoleRateFragment from "../role-rate/role-rate.fragment";
import UserFragment from "../user";
import TagFragment from "../tags/tag.fragment";

const shiftFragment = gql`
  # Fragment imports
  ${VenueFragment}
  ${RoleRateFragment}
  ${UserFragment}
  ${TagFragment}

  # Parent shift fragment
  fragment ShiftFragment on shift {
    id
    name
    seenCount
    identifier
    startTime
    endTime
    type
    numberRequested
    overbookPercentage
    tags {
      ...TagFragment
    }
    briefing
    notes
    isRequiringReview
    isAutoAcceptEnabled
    isFeatured
    totalInGroup
    approvalState
    sourceAccount {
      id
      accountName
      logo
    }
    targetAccount {
      id
      accountName
      logo
      isPinAutomateTimesheetApproval
    }
    bookingCounts {
      applied
      accepted
      matched
      visibleTo
      total
      excludedTotal
      waitingList
      noShow
      turnedAway
      cancelled
    }
    venue {
      ...VenueFragment
    }
    roleRate {
      ...RoleRateFragment
    }
    user {
      ...UserFragment
    }

    cancelledAt
    finalisedAt
    reviewedAt
    openedAt
    editedBySourceAt
    postedAt
    shiftGroupId
    shiftGroupColor
    originalStartTime
    originalEndTime
    skipAlgoShift
    delayHoursAlgoShift
    isRestricted
    isShiftRebook
    postedAt
    breakMinutes
    isPartnerManaged

    stream {
      isAutomate
      sources {
        id
        numberRequested
        url
      }
      account {
        id
        source
        sourceName
        name
        url
      }
    }
  }
`;

export default shiftFragment;

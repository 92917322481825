import { gql, useQuery } from "@apollo/client";

export const GET_SHIFT_BOOKINGS_BY_ID = gql`
  query getShiftBookingsById($id: ID!) {
    account {
      shift(id: $id) {
        id
        finalisedAt
        openedAt
        cancelledAt
        seenCount

        isAutoAcceptEnabled

        bookingCounts {
          applied
          accepted
          matched
          visibleTo
          total
          excludedTotal
          waitingList
          noShow
          turnedAway
          cancelled
        }
      }
    }
  }
`;

export const useShiftWithBookings = shift => {
  const { loading, error, data, refetch } = useQuery(GET_SHIFT_BOOKINGS_BY_ID, {
    variables: { id: shift?.id },
    fetchPolicy: "no-cache",
    skip: !shift?.id
  });

  return {
    shiftWithBookings: data?.account?.shift || {},
    shiftWithBookingsLoading: loading,
    shiftWithBookingsgRefetch: refetch,
    shiftWithBookingsError: error
  };
};

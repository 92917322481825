import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { iconNew } from "@teamrota/rota-design";

import { FocusShiftContext } from "~/src/containers/provide-schedule/FocusShift";

import WeeklyView from "./WeeklyView/weekly-view";
import DailyView from "./DailyView/daily-view";

import styles, {
  StyledIcon,
  StyledOpenShifts,
  StyledMainColumn,
  StyledIconsContainer,
  StyledGridHeader,
  StyledDay
} from "./table-row.styles";

const { Sun, Moon } = iconNew;

const OpenShiftTableRow = ({
  schedule,
  onPressGridCell,
  onPressShiftCell,
  showCancelledShifts,
  selectedDayIndex,
  setSelectedDayIndex,
  assignMemberLoading
}) => {
  const [unassignedShifts, setUnassignedShifts] = useState([]);

  const { clearFocusShift } = useContext(FocusShiftContext);
  const [lastDraggedShiftId, setLastDraggedShiftId] = useState(null);

  useEffect(() => {
    if (showCancelledShifts) {
      setUnassignedShifts(schedule?.unassignedShifts);
    }
    if (!showCancelledShifts) {
      setUnassignedShifts(
        schedule?.unassignedShifts?.map(({ date, shifts }) => ({
          date,
          shifts: [...shifts.filter(shift => !shift?.cancelledAt)]
        }))
      );
    }
  }, [schedule, showCancelledShifts]);

  useEffect(() => {
    if (unassignedShifts?.length > 0) {
      const shifts = [];
      unassignedShifts?.forEach(shift => {
        shifts.push(shift);
      });
    }
  }, [unassignedShifts]);

  const getInitialShiftData = (rowDayIndex, startTime) => {
    onPressGridCell({
      startTime
    });
  };

  const isWeeklyView = selectedDayIndex === null;
  const isDailyView = selectedDayIndex !== null;

  return (
    <>
      {isDailyView && (
        <StyledGridHeader>
          <StyledDay>
            {moment(schedule?.days?.[selectedDayIndex])?.format("dddd Do MMMM")}
          </StyledDay>
        </StyledGridHeader>
      )}

      <StyledOpenShifts isWeeklyView={isWeeklyView}>
        {(isWeeklyView || isDailyView) && (
          <StyledMainColumn isDailyView={isDailyView}>
            <StyledIcon name="CLOCK_SCHEDULER" size="LARGE" />
            <p style={styles.title}>Open shifts</p>

            {isDailyView && (
              <StyledIconsContainer>
                <Sun /> <Moon />
              </StyledIconsContainer>
            )}
          </StyledMainColumn>
        )}

        {isWeeklyView && (
          <WeeklyView
            setSelectedDayIndex={setSelectedDayIndex}
            lastDraggedShiftId={lastDraggedShiftId}
            setLastDraggedShiftId={setLastDraggedShiftId}
            unassignedShifts={unassignedShifts}
            getInitialShiftData={getInitialShiftData}
          />
        )}

        {isDailyView && (
          <DailyView
            selectedDayIndex={selectedDayIndex}
            lastDraggedShiftId={lastDraggedShiftId}
            setLastDraggedShiftId={setLastDraggedShiftId}
            unassignedShifts={unassignedShifts}
            getInitialShiftData={getInitialShiftData}
            assignMemberLoading={assignMemberLoading}
            onShiftDetails={onPressShiftCell}
            onExit={() => {
              clearFocusShift();
              setSelectedDayIndex(null);
            }}
          />
        )}
      </StyledOpenShifts>
    </>
  );
};

const mapStateToProps = s => ({
  showCancelledShifts: s.scheduler?.showCancelledShifts
});

export default connect(mapStateToProps)(OpenShiftTableRow);

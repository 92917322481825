import styled from "styled-components";
import Icon from "~/src/components/icon";

export const StyledFocusContainer = styled.div`
  position: relative;
  height: 45px;

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 2px;

  padding: 5px 5px 0 5px;
`;

export const StyledIcon = styled(Icon)`
  margin: 0 10px;
  cursor: pointer;
`;

export const StyledMenu = styled.div`
  position: absolute;
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  padding: 5px;
  top: calc(100% - 10px);
  left: -5px;
  width: calc(100% - 30px);
  margin: 10px;
  background-color: ${({ theme }) => theme.neutral.lighter};
  justify-content: left;
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.neutral.main};
  z-index: 50000;
`;

export const StyledDate = styled.span`
  color: ${({ theme }) => theme.neutral.main};
  font-size: 11px;
  padding-left: 6px;
`;

export const StyledStat = styled.div`
  position: relative;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 5px;
`;

export const StyledStatNumber = styled.span`
  position: absolute;
  top: 0;
  right: 55px;
  font-weight: 600;
  padding: 10px 5px;
`;

export const StyledStatPercent = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
  padding: 10px 5px;
`;

import React, { useEffect } from "react";
import { useDrag } from "react-dnd";

import { SHIFT_TYPES, DND_ITEM_TYPES } from "~/src/consts";
import { formatHours } from "~/src/containers/scheduler/utils";

import {
  StyledOpenShiftContainer,
  Div,
  GridCardWrapper
} from "~/src/containers/scheduler/OpenShifts/OpenShiftDailyCard/open-shift-daily-card.styles";

import { StyledMaskContainer } from "../../GridCard/grid-card.styles";

export const OpenShiftDailyCard = ({
  shift,
  assignMemberLoading,
  lastDraggedShiftId,
  setLastDraggedShiftId,
  focusShift,
  onFocusShift
}) => {
  const isCancelled = !!shift?.cancelledAt;
  const hasCancelledBookings = !!shift?.hasCancelledBookings;

  const isDraft = !!shift?.isDraft;
  const isNightShift = shift.type === SHIFT_TYPES.NIGHT;
  const isStream = !!shift.stream;

  const [{ draggedShift, isDragging }, drag] = useDrag(
    () => ({
      type: DND_ITEM_TYPES.OPEN_SHIFT,
      item: () => shift,
      canDrag: () => true,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        draggedShift: monitor.getItem()
      })
    }),
    [shift]
  );

  useEffect(() => {
    if (isDragging && draggedShift.id !== lastDraggedShiftId) {
      setLastDraggedShiftId(draggedShift?.id);
    }
  }, [isDragging]);

  const isShiftLoading =
    assignMemberLoading && lastDraggedShiftId === shift?.id;

  const isFaded = focusShift !== null && focusShift?.id !== shift.id;

  return (
    <StyledOpenShiftContainer className={isFaded ? "faded" : ""}>
      <GridCardWrapper
        ref={drag}
        lineHeight="12px"
        isDraft={isDraft}
        isCancelled={isCancelled}
        hasCancelledBookings={hasCancelledBookings}
        isNightShift={isNightShift}
        isStream={isStream}
        onClick={() => onFocusShift(shift)}
        isLoading={isShiftLoading}
      >
        <StyledMaskContainer>
          <Div>
            {`${shift?.bookings.totalResults}/${shift?.numberRequested} ${shift.roleRate.roleName}`}{" "}
          </Div>
          <Div fontWeight="500">{formatHours(shift)}</Div>
          <Div>{`${shift?.venue?.name} (${shift?.sourceAccount.accountName})`}</Div>
        </StyledMaskContainer>
      </GridCardWrapper>
    </StyledOpenShiftContainer>
  );
};

import React from "react";
import moment from "moment-timezone";
import { colors } from "@teamrota/rota-design";

import { ACCOUNT_TIMEZONE } from "~/src/consts";

import { getDefaultShiftStartTime } from "../utils";

import {
  PlusIcon,
  StyledOpenShiftEmptyCard
} from "../../../GridCard/grid-card.styles";

import OpenShiftWeeklyCard from "../../OpenShiftWeeklyCard/open-shift-weekly-card";
import { StyledOpenShiftCell, StyledWeeklyView } from "./weekly-view.styles";

const WeeklyView = ({
  unassignedShifts,
  getInitialShiftData,
  setSelectedDayIndex,
  lastDraggedShiftId,
  setLastDraggedShiftId
}) => {
  return (
    <StyledWeeklyView>
      {unassignedShifts?.map((unassignedShift, i) => {
        const shifts = unassignedShift?.shifts;

        const unassignedDayStart =
          unassignedShift?.date &&
          moment.tz(unassignedShift.date, ACCOUNT_TIMEZONE).startOf("day");

        const unassignedDayEnd = unassignedDayStart.endOf("day");

        const isDayInFuture =
          unassignedDayEnd && unassignedDayEnd.isAfter(moment());

        return (
          <StyledOpenShiftCell
            key={i}
            isBookings={shifts?.length > 0}
            isDayInFuture={isDayInFuture}
          >
            {shifts?.length > 0 && (
              <OpenShiftWeeklyCard
                shifts={shifts}
                setSelectedDayIndex={setSelectedDayIndex}
                dayIndex={i}
                lastDraggedShiftId={lastDraggedShiftId}
                setLastDraggedShiftId={setLastDraggedShiftId}
              />
            )}

            {shifts?.length === 0 && isDayInFuture && (
              <StyledOpenShiftEmptyCard
                lineHeight="4px"
                onClick={() =>
                  getInitialShiftData(
                    i,
                    getDefaultShiftStartTime(unassignedDayStart)
                  )
                }
              >
                <PlusIcon name="PLUS" size="small" color={colors.rotaOrange} />
              </StyledOpenShiftEmptyCard>
            )}
          </StyledOpenShiftCell>
        );
      })}
    </StyledWeeklyView>
  );
};

export default WeeklyView;

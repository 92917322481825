import React, { memo, useContext } from "react";
import moment from "moment-timezone";

import {
  DateText,
  StyledDayContainer,
  Wrapper
} from "~/src/containers/scheduler/SchedulerGrid/scheduler-grid.styles";

import { FocusShiftContext } from "~/src/containers/provide-schedule/FocusShift";

const DateHeaders = ({
  startTime,
  selectedDayIndex,
  setSelectedDayIndex,
  isDailyView,
  isProviderScheduler
}) => {
  const { clearFocusShift } = useContext(FocusShiftContext);

  return (
    <Wrapper isProviderScheduler={isProviderScheduler}>
      {new Array(7).fill(0).map((_, i) => {
        const mom = moment(startTime)?.add(i, "d");
        const dateTitle = moment(mom)?.format("ddd Do");
        const isToday = mom?.isSame(moment(), "d");

        return (
          <StyledDayContainer>
            <DateText
              key={i}
              isToday={isToday}
              isActive={selectedDayIndex === i}
              isDailyView={isDailyView}
              onClick={() => {
                clearFocusShift();
                setSelectedDayIndex(i);
              }}
            >
              {dateTitle}
            </DateText>
          </StyledDayContainer>
        );
      })}
    </Wrapper>
  );
};

export default memo(DateHeaders);

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

export const StyledScheduleCells = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledMaskContainer = styled.div`
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

export const GridCardWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  height: 50px;

  background: ${({
    isNightShift,
    isStream,
    isShiftCancelled,
    isBookingCancelled,
    isDraft,
    theme
  }) =>
    isShiftCancelled
      ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#ffeaea 10px 20px)"
      : isBookingCancelled
      ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#eebb0055 10px 20px)"
      : isDraft
      ? theme.white
      : isStream && isNightShift
      ? "#052d63"
      : isStream
      ? "#A3CAFF"
      : isNightShift
      ? "#2C2C2E"
      : theme.primary.lighter};
  border: ${({ isDraft }) => (isDraft ? "2px dashed" : "2px solid")};
  border-color: ${({ isShiftCancelled, isStream, isBookingCancelled, theme }) =>
    isShiftCancelled
      ? theme.error.main
      : isBookingCancelled
      ? theme.warning.main
      : isStream
      ? "#2f84f7"
      : theme.primary.main};
  border-radius: 5px;
  padding: 5px;
  line-height: ${({ lineHeight }) => lineHeight};
  overflow-x: hidden;
  overflow-y: ${({ scrollable }) => scrollable || "hidden"};
  margin: 0 1px;
  color: ${({ isNightShift, isDraft, isShiftCancelled, isBookingCancelled }) =>
    isNightShift && !(isDraft || isShiftCancelled || isBookingCancelled)
      ? "white"
      : "black"};
  cursor: pointer;
  line-height: 12px;
`;

export const MultipleShiftsCard = styled.div`
  width: 100%;
  cursor: pointer;
  border: #ffdb21 1px solid;
  white-space: pre-wrap;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fffcb6;
  border-radius: 7px;
  padding: 5px;
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const PlusIcon = styled(Icon)`
  vertical-align: middle;
  display: none;
`;

export const StyledOpenShiftEmptyCard = styled.div`
  position: absolute;
  top: ${({ isDailyView }) => (isDailyView ? "10" : "18")}px;
  left: 5%;
  width: 90%;

  height: ${({ isDailyView }) => (isDailyView ? "107" : "143")}px;
  border-radius: 7px;
  border-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `2px dashed ${theme.primary.main}`};
  border: 2px dashed ${({ theme }) => theme.primary.main};
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  ${PlusIcon} {
    display: block;
  }

  ${({ disabled }) => (disabled ? "opacity: 0.3; filter: grayscale(1);" : "")};
`;

export const GridCardEmptyWrapper = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 7px;
  border-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border: 2px solid silver;
  }
  &:hover ${PlusIcon} {
    display: block;
  }
`;

export const StyledShiftCell = styled.div`
  border: ${({ theme }) => `0.5px solid ${theme.background.primary}`};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-left: 3px;
  padding-right: 3px;
  height: 60px;

  background: ${({ isNightShiftCell, theme, isCellLoading }) =>
    isCellLoading
      ? `linear-gradient(90deg, ${
          isNightShiftCell ? theme.neutral.dark : theme.primary.lighter
        }, ${isNightShiftCell ? theme.neutral.main : theme.white}, ${
          isNightShiftCell ? theme.neutral.dark : theme.primary.lighter
        })`
      : theme.white};

  background-size: 400% 100%;

  animation: ${({ isCellLoading }) =>
    isCellLoading ? "gradient 2s ease infinite" : "none"};

  @keyframes gradient {
    0% {
      background-position-x: 0;
    }
    50% {
      background-position-x: 100%;
    }
    100% {
      background-position-x: 0;
    }
  }

  ${({ isGreyCell, theme }) =>
    isGreyCell ? `background-color: ${theme.neutral.lighter};` : ""}

  ${({ isHideCell, theme }) =>
    isHideCell
      ? `background-color: ${theme.neutral.lighter};`
      : ""}
  

  &:after {
    display: ${({ highlightCell }) => (highlightCell ? "block" : "none")};
    content: " ";
    opacity: 0.8;
    position: absolute;

    top: 4px;
    left: 4px;
    height: calc(100% - 12px);
    width: calc(100% - 12px);

    background: ${({ theme }) => theme.primary.lighter};

    border: ${({ theme }) => `2px solid ${theme.background.primary}`};
    border-radius: 5px;
  }

  & * {
    ${({ isHideCell }) => isHideCell && "visibility: hidden;"};
  }
`;

export const Div = styled.div`
  display: flex;
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: center;
  line-height: ${({ lineHeight }) => lineHeight};
  font-size: ${({ fontSize }) => fontSize || STYLE_CONSTS.FONT};
  font-weight: ${({ fontWeight }) => fontWeight || STYLE_CONSTS.FONT_WEIGHT};
  text-transform: ${({ textTransform }) => textTransform};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  white-space: ${({ wrap }) => wrap || "nowrap"};
  max-width: 10px;
  overflow: visible;
`;

export const IconWrapper = styled.span`
  background-color: #c4c4c4;
  height: 100%;
  width: 30px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { iconNew, RotaButtonIcon, RotaInput } from "@teamrota/rota-design";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { TimeInputRef } from ".";

const { Edit, Tick } = iconNew;

export const OuterWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  display: flex;
  align-items: center;
`;

interface Props {
  isDisabled: boolean;
  initialValue: string;
  type: "date" | "time";
  onUpdate: (value: string, onSuccess: () => void) => void;
  placeholder?: string;
  error?: string;
}

const ScheduledTimeInput = forwardRef<TimeInputRef, Props>(
  (
    { isDisabled, initialValue, type, onUpdate, placeholder, error }: Props,
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);

    useImperativeHandle(ref, () => ({
      updateValue: (newValue: string) => {
        setValue(newValue);
      }
    }));

    return (
      <OuterWrapper>
        <Wrapper>
          <RotaInput
            placeholder={placeholder || initialValue}
            disabled={!isEditing || isDisabled}
            type={type}
            value={value}
            onChange={e => setValue(e.target.value)}
            isError={!!error}
            errorMessage={error}
          />
        </Wrapper>
        {isEditing ? (
          <RotaButtonIcon
            onClick={() => {
              onUpdate(value, () => setIsEditing(false));
            }}
          >
            <Tick />
          </RotaButtonIcon>
        ) : (
          <RotaButtonIcon onClick={() => setIsEditing(true)}>
            <Edit />
          </RotaButtonIcon>
        )}
      </OuterWrapper>
    );
  }
);

export default ScheduledTimeInput;

import React, { memo } from "react";

import Icon from "~/src/components/icon";
import { Container, Button, Text } from "./styles";
import { COLORS } from "~/src/styles/config.style";

interface TinyButtonProps {
  text: string;
  icon: string;
  isDisabled?: boolean;
  onClick: () => void;
}

const TinyButton: React.FC<TinyButtonProps> = ({
  text,
  icon,
  isDisabled = false,
  onClick
}) => {
  return (
    <Container isDisabled={isDisabled} onClick={() => onClick()}>
      <Button isDisabled={isDisabled}>
        <Icon
          name={Icon.names[icon.toUpperCase()]}
          size={18}
          color={isDisabled ? COLORS.GREY_MEDIUM : COLORS.WHITE}
          onClick={() => onClick()}
        />
      </Button>

      {text && <Text isDisabled={isDisabled}>{text}</Text>}
    </Container>
  );
};

export default memo(TinyButton);

import React from "react";

export function getFulfilmentSnack(shift, scheduledMember) {
  const who = `${scheduledMember?.member?.firstName} ${scheduledMember?.member?.lastName}`;

  if (shift?.stream && shift?.stream?.account?.source) {
    if (shift?.stream.isAutomate) {
      return {
        size: 2,
        severity: "success",
        message: (
          <div>
            <div>
              ShiftStream shift will be automatically assigned to {who} on{" "}
              {shift.stream.account.sourceName} - {shift.stream.account.name}
            </div>

            <div>
              Source Refs(s):{" "}
              {shift.stream.sources.map(({ id, url, numberRequested }, idx) => (
                <>
                  {idx > 0 ? ", " : ""}
                  <a href={url} target="_blank">
                    {id}
                  </a>{" "}
                  ({numberRequested})
                </>
              ))}
            </div>
          </div>
        )
      };
    } else {
      return {
        size: 2,
        severity: "warning",
        message: (
          <div>
            <div>
              You must now assign this ShiftStream shift to {who} on{" "}
              {shift.stream.account.sourceName} - {shift.stream.account.name}
            </div>

            <div>
              Source Refs(s):{" "}
              {shift.stream.sources.map(({ id, url, numberRequested }, idx) => (
                <>
                  {idx > 0 ? ", " : ""}
                  <a href={url} target="_blank">
                    {id}
                  </a>{" "}
                  ({numberRequested})
                </>
              ))}
            </div>
          </div>
        )
      };
    }
  } else {
    return {
      size: 1,
      severity: "success",
      message: (
        <div>
          <div>Successfully assigned shift to {who}</div>
        </div>
      )
    };
  }
}

import React, { useRef, useState } from "react";
import moment from "moment-timezone";

import { InnerCell, Td } from "@teamrota/rota-design";
import { ZonedDate } from "@teamrota/rota-common";

import { MAX_SHIFT_DURATION_HOURS } from "~/src/consts";

import ScheduledTimeInput from "./schedule-times-input";
import {
  changeDateTimeToDateString,
  changeDateTimeToTimeString
} from "../utils";

const MAX_DURATION_MESSAGE = `Shift duration cannot be greater than ${MAX_SHIFT_DURATION_HOURS} hours.`;
const INVALID_TIMES_MESSAGE = "Start time must be before the shift end time.";

interface Props {
  id: string;
  isDisabled: boolean;
  scheduledStartTime: string;
  scheduledEndTime: string;
  updateTimesheetMember: (variables: any) => Promise<any>;
  loading: boolean;
}
type ScheduleUpdateType = {
  type: "startDate" | "endDate" | "startTime" | "endTime";
  value: string;
  id: string;
};

interface ScheduleState {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export interface TimeInputRef {
  updateValue: (value: string) => void;
}

const ScheduledTimes = ({
  id,
  scheduledStartTime,
  scheduledEndTime,
  isDisabled,
  updateTimesheetMember,
  loading
}: Props) => {
  const [scheduleValues, setScheduleValues] = useState<ScheduleState>({
    startDate: changeDateTimeToDateString(scheduledStartTime),
    startTime: changeDateTimeToTimeString(scheduledStartTime),
    endDate: changeDateTimeToDateString(scheduledEndTime),
    endTime: changeDateTimeToTimeString(scheduledEndTime)
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const endDateRef = useRef<TimeInputRef>(null);

  const validateScheduleTimes = (
    newValues: ScheduleState,
    updateType: ScheduleUpdateType["type"]
  ): boolean => {
    const startDateTime = new ZonedDate(
      `${newValues.startDate} ${newValues.startTime}`
    );
    const endDateTime = new ZonedDate(
      `${newValues.endDate} ${newValues.endTime}`
    );

    // Clear previous errors
    const newErrors: { [key: string]: string } = {};

    const isStartTimeAfterEndTime = moment(endDateTime).isBefore(
      startDateTime,
      "minute"
    );
    if (isStartTimeAfterEndTime) {
      newErrors[updateType] = INVALID_TIMES_MESSAGE;
      setErrors(newErrors);
      return false;
    }

    // Check for 36 hour limit
    const minutesDifference = moment(endDateTime).diff(
      moment(startDateTime),
      "minutes"
    );

    if (minutesDifference > MAX_SHIFT_DURATION_HOURS * 60) {
      // Set error on the field being updated
      newErrors[updateType] = MAX_DURATION_MESSAGE;
      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  };

  const handleScheduleUpdate = async (
    { id, type, value }: ScheduleUpdateType,
    onSuccess: () => void
  ) => {
    const newValues = { ...scheduleValues };

    if (type === "startDate") {
      const oldStartDate = moment(scheduleValues.startDate);
      const newStartDate = moment(value);
      const daysDifference = newStartDate.diff(oldStartDate, "days");

      newValues.startDate = value;
      const newEndDate = moment(scheduleValues.endDate)
        .add(daysDifference, "days")
        .format("YYYY-MM-DD");
      newValues.endDate = newEndDate;

      // Update the end date input with the same difference as the start
      const endDateInput = endDateRef.current;
      if (endDateInput) {
        endDateInput.updateValue(newEndDate);
      }
    }

    newValues[type] = value;

    if (!validateScheduleTimes(newValues, type)) {
      return;
    }

    try {
      await updateTimesheetMember({
        id,
        scheduledStartTime: new ZonedDate(
          `${newValues.startDate} ${newValues.startTime}`
        ),
        scheduledEndTime: new ZonedDate(
          `${newValues.endDate} ${newValues.endTime}`
        )
      });

      setScheduleValues(newValues);
      onSuccess();
    } catch (error) {
      console.error("Failed to update timesheet:", error);
    }
  };

  const onUpdateInput = (type: ScheduleUpdateType["type"]) => (
    value: string,
    onSuccess: () => void
  ) => {
    handleScheduleUpdate(
      {
        id,
        type,
        value
      },
      onSuccess
    );
  };

  return (
    <>
      <Td>
        <InnerCell>
          <ScheduledTimeInput
            initialValue={changeDateTimeToDateString(scheduledStartTime)}
            isDisabled={isDisabled || loading}
            type="date"
            error={errors.startDate}
            onUpdate={onUpdateInput("startDate")}
          />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <ScheduledTimeInput
            initialValue={changeDateTimeToTimeString(scheduledStartTime)}
            type="time"
            error={errors.startTime}
            isDisabled={isDisabled}
            onUpdate={onUpdateInput("startTime")}
          />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <ScheduledTimeInput
            ref={endDateRef}
            initialValue={changeDateTimeToDateString(scheduledEndTime)}
            isDisabled={isDisabled}
            type="date"
            error={errors.endDate}
            onUpdate={onUpdateInput("endDate")}
          />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <ScheduledTimeInput
            initialValue={changeDateTimeToTimeString(scheduledEndTime)}
            type="time"
            error={errors.endTime}
            isDisabled={isDisabled}
            onUpdate={onUpdateInput("endTime")}
          />
        </InnerCell>
      </Td>
    </>
  );
};

export default ScheduledTimes;

import React from "react";

import { StyledInfoBox } from "./stream-info.styles";

export default function StreamInfo({ stream }) {
  return (
    <StyledInfoBox>
      <div>
        ShiftStream shift from &nbsp;
        <a href={stream?.account?.url} target="_blank">
          {stream?.account?.sourceName} - {stream?.account?.name}
        </a>
      </div>
      <div>
        Source Refs(s):{" "}
        {stream.sources.map(({ id, url, numberRequested }, idx) => (
          <>
            {idx > 0 ? ", " : ""}
            <a href={url} target="_blank">
              {id}
            </a>{" "}
            ({numberRequested})
          </>
        ))}
      </div>
    </StyledInfoBox>
  );
}

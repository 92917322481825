import React, { useContext, useEffect, useState } from "react";

import { CANCELLATION_REASONS, SHIFT_STATES } from "~/src/consts";
import { formatDateMonthTimeShort } from "~/src/utils/formatting";

import RequestAndAssignModal from "~/src/containers/provide/components/more-options-menu/request-assign-modal";
import { FocusShiftContext } from "~/src/containers/provide-schedule/FocusShift";
import asyncConfirm from "~/src/utils/async-confirm";
import { errorModal } from "~/src/utils/errors";

import updateShiftState from "./graphql/update-shift-state";
import { useShiftWithBookings } from "./graphql/use-shift-with-bookings";

import TinyButton from "./TinyButton";

import {
  StyledFocusContainer,
  StyledMenu,
  StyledDate,
  StyledStat,
  StyledStatNumber,
  StyledStatPercent
} from "./focus-actions.styles";

function FocusActions({ onShiftDetails, updateShiftState }) {
  const { focusShift, setFocusShift, clearFocusShift } = useContext(
    FocusShiftContext
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRequestAndAssignOpen, setIsRequestAndAssignOpen] = useState(false);

  const {
    shiftWithBookings,
    shiftWithBookingsgRefetch,
    shiftWithBookingsLoading
  } = useShiftWithBookings(focusShift);

  useEffect(() => {
    setIsMenuOpen(false);
    setIsRequestAndAssignOpen(false);
  }, [focusShift?.id]);

  const setNewShiftState = async (state, note) => {
    try {
      await updateShiftState(focusShift.id, state.toLowerCase(), note);
      await shiftWithBookingsgRefetch();
    } catch (e) {
      errorModal(e);
    }
  };

  const handleCancelShift = async () => {
    try {
      const ret = await asyncConfirm(
        "Are you sure you want to cancel this shift?",
        {
          confirmButtonText: "Cancel shift",
          falseButtonText: "Back",
          callback: () => {},
          dropDownOptions: Object.values(CANCELLATION_REASONS).map(reason => ({
            value: reason,
            label: reason
          }))
        }
      );

      if (ret) {
        await setNewShiftState(SHIFT_STATES.CANCELLED.toLowerCase(), ret);
      }
    } catch (e) {
      errorModal(e);
    }
  };

  const isAutoAccept = shiftWithBookings.isAutoAcceptEnabled || false;

  const cancelledAt = shiftWithBookings.cancelledAt || null;
  const openedAt = shiftWithBookings.openedAt || null;
  const finalisedAt = shiftWithBookings.finalisedAt || null;

  const accepted = shiftWithBookings?.bookingCounts?.accepted;
  const excludedTotal = shiftWithBookings?.bookingCounts?.excludedTotal;

  const seenCount = shiftWithBookings.seenCount;
  const visibleTo = shiftWithBookings?.bookingCounts?.visibleTo || 0;

  const visibleToPercent = Math.round((visibleTo / excludedTotal) * 100) || 0;
  const seenCountPercent = Math.round((seenCount / visibleTo) * 100) || 0;
  const fulfilledPercent =
    Math.round((accepted / focusShift?.numberRequested) * 100) || 0;

  useEffect(() => {
    // if this was a reload of this shift that was called
    // update fields we care about
    if (
      focusShift &&
      shiftWithBookings &&
      shiftWithBookings?.id === focusShift?.id
    ) {
      if (cancelledAt) {
        clearFocusShift();
      } else {
        setFocusShift({ ...focusShift, ...shiftWithBookings }, true);
      }
    }
  }, [shiftWithBookingsLoading]);

  if (focusShift === null) {
    return "";
  }

  return (
    <>
      <StyledFocusContainer onPointerLeave={() => setIsMenuOpen(false)}>
        <StyledMenu isShow={isMenuOpen}>
          <TinyButton
            icon="ADD_MEMBER"
            text="Request/Assign"
            onClick={() => {
              setIsRequestAndAssignOpen(true);
              setIsMenuOpen(false);
            }}
          />

          <StyledStat>
            Visible To:
            <StyledStatNumber>{visibleTo}</StyledStatNumber>
            <StyledStatPercent>{visibleToPercent}%</StyledStatPercent>
          </StyledStat>
          <StyledStat>
            Seen By:
            <StyledStatNumber>{seenCount}</StyledStatNumber>
            <StyledStatPercent>{seenCountPercent}%</StyledStatPercent>
          </StyledStat>
          <StyledStat>
            Fulfilment:
            <StyledStatNumber>
              {accepted}/{focusShift?.numberRequested}
            </StyledStatNumber>
            <StyledStatPercent>{fulfilledPercent}%</StyledStatPercent>
          </StyledStat>
          <StyledStat>
            Auto Accept {isAutoAccept ? "Enabled" : "Disabled"}
          </StyledStat>

          <TinyButton
            icon="STAR_FILLED"
            text="Toggle Auto Accept"
            onClick={() => {
              setNewShiftState("auto_accept");
              setIsMenuOpen(false);
            }}
          />

          <TinyButton
            icon="VISIBLE"
            text={
              openedAt !== null ? (
                <>
                  Opened
                  <StyledDate>{formatDateMonthTimeShort(openedAt)}</StyledDate>
                </>
              ) : (
                "Open shift"
              )
            }
            isDisabled={openedAt !== null}
            onClick={() => {
              setNewShiftState(SHIFT_STATES.VISIBLE);
              setIsMenuOpen(false);
            }}
          />

          <TinyButton
            icon="CLOCK"
            text={
              finalisedAt !== null ? (
                <>
                  Finalised
                  <StyledDate>
                    {formatDateMonthTimeShort(finalisedAt)}
                  </StyledDate>
                </>
              ) : (
                "Finalise shift"
              )
            }
            isDisabled={finalisedAt !== null}
            onClick={() => {
              setNewShiftState(SHIFT_STATES.FINALISED);
              setIsMenuOpen(false);
            }}
          />

          <TinyButton
            icon="DELETE"
            text={
              cancelledAt !== null ? (
                <>
                  Cancelled
                  <StyledDate>
                    {formatDateMonthTimeShort(cancelledAt)}
                  </StyledDate>
                </>
              ) : (
                "Cancel shift"
              )
            }
            isDisabled={cancelledAt !== null}
            onClick={() => {
              handleCancelShift();
              setIsMenuOpen(false);
            }}
          />
        </StyledMenu>

        <TinyButton
          icon="INFO"
          text="View"
          onClick={() => onShiftDetails(focusShift, false)}
        />

        <TinyButton
          icon="EDIT"
          text="Edit"
          onClick={() => onShiftDetails(focusShift, true)}
        />

        <TinyButton
          icon="MORE"
          isNight
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        />
      </StyledFocusContainer>

      {focusShift && isRequestAndAssignOpen && (
        <RequestAndAssignModal
          roleId={focusShift.roleRate.roleId}
          sourceAccountId={focusShift.sourceAccountId}
          shiftId={focusShift.id}
          venueId={focusShift.venue.id}
          roleRateId={focusShift.roleRate.id}
          refetch={() => {}}
          dates={{
            startTime: focusShift.startTime,
            endTime: focusShift.endTime
          }}
          shiftType={focusShift.shiftType}
          onClose={() => setIsRequestAndAssignOpen(false)}
        />
      )}
    </>
  );
}

export default updateShiftState(FocusActions);

import React, { useState } from "react";
import styled from "styled-components";
import { RotaDropdownHeader, RotaRadio } from "@teamrota/rota-design";
import { SORT_BY } from "~/src/consts";

const StyledContainer = styled.div`
  & > div > div {
    min-width: 210px;
  }
`;

const StyledDropdownsContainer = styled.div`
  padding: 8px;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  border: solid 1px ${({ theme }) => theme.neutral.light};
  background-color: ${({ theme }) => theme.white};
`;

const StyledSort = styled.span`
  font-weight: 500;
`;

const LABELS = {
  [SORT_BY.FIRSTNAME]: "First Name",
  [SORT_BY.LASTNAME]: "Last Name",
  [SORT_BY.MOST_SHIFTS_WORKED]: "Most Shifts",
  [SORT_BY.LEAST_SHIFTS_WORKED]: "Least Shifts"
};

const SortDropdown = ({ setSortBy, sortBy }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledContainer>
      <RotaDropdownHeader
        labelText={
          <>
            Sort By: <StyledSort>{LABELS[sortBy]}</StyledSort>
          </>
        }
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(isOpen => !isOpen)}
      >
        <StyledDropdownsContainer>
          <RotaRadio
            name={SORT_BY.FIRSTNAME}
            label={LABELS[SORT_BY.FIRSTNAME]}
            checked={sortBy === SORT_BY.FIRSTNAME}
            onChange={e => setSortBy(e.target.name)}
          />

          <RotaRadio
            name={SORT_BY.LASTNAME}
            label={LABELS[SORT_BY.LASTNAME]}
            checked={sortBy === SORT_BY.LASTNAME}
            onChange={e => setSortBy(e.target.name)}
          />

          <RotaRadio
            name={SORT_BY.MOST_SHIFTS_WORKED}
            label={LABELS[SORT_BY.MOST_SHIFTS_WORKED]}
            checked={sortBy === SORT_BY.MOST_SHIFTS_WORKED}
            onChange={e => setSortBy(e.target.name)}
          />

          <RotaRadio
            name={SORT_BY.LEAST_SHIFTS_WORKED}
            label={LABELS[SORT_BY.LEAST_SHIFTS_WORKED]}
            checked={sortBy === SORT_BY.LEAST_SHIFTS_WORKED}
            onChange={e => setSortBy(e.target.name)}
          />
        </StyledDropdownsContainer>
      </RotaDropdownHeader>
    </StyledContainer>
  );
};

export default SortDropdown;
